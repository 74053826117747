<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'ThePreloader',
  components: {},
  setup () {
    const el = ref()
    const eclipse = ref()
    const loadingTextSvg = ref()
    const mask = ref()

    const leaveAnimationType = ref()
    const visible = ref(false)
    const semitransparent = ref(false)
    const entered = ref(false)

    const timeline = gsap.timeline()
    const eclipseTimeline = gsap.timeline({ repeat: -1 })
    const maskTimeline = gsap.timeline({ repeat: -1 })

    onMounted(() => {
      timeline.set(el.value, { autoAlpha: 0 })
    })

    const enter = () => {
      eclipseTimeline.fromTo(
        eclipse.value,
        { y: '-100%' },
        {
          y: '100%',
          duration: 6,
          yoyo: true,
          repeat: -1,
          repeatDelay: 0,
          yoyoEase: 'power2.inOut',
          ease: 'power2.inOut'
        })
      maskTimeline.fromTo(
        mask.value, {
          attr: { x: '-15%' }
        },
        {
          attr: { x: '115%' },
          duration: 2,
          yoyo: true,
          repeat: -1,
          repeatDelay: 0,
          yoyoEase: 'power2.out',
          ease: 'power2.out'
        })
      timeline.fromTo(
        el.value,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.2 })
      timeline.add(() => (entered.value = true))
    }

    const leave = () => {
      eclipseTimeline.clear()
      maskTimeline.clear()
      if (leaveAnimationType.value === 'Home') {
        timeline.fromTo(el.value, { xPercent: 0 }, { xPercent: -100, duration: 1, ease: 'power2.in' })
      } else {
        timeline.fromTo(el.value, { autoAlpha: 1 }, { autoAlpha: 0 })
      }
      timeline.set([el.value, loadingTextSvg.value], { clearProps: 'all' })
      timeline.set(el.value, { autoAlpha: 0, x: 0 })
      timeline.set(loadingTextSvg.value, { autoAlpha: 1 })
      timeline.add(() => (entered.value = false))
    }

    watch([visible, entered], ([newVisible, newEntered], [oldVisible, oldEntered]) => {
      if (newVisible && !oldVisible && !oldEntered) enter()
      if (!newVisible && newEntered) leave()
    })

    return {
      semitransparent,
      el,
      eclipse,
      loadingTextSvg,
      mask,
      leaveAnimationType,
      visible,
      entered
    }
  }
})
</script>

<template>
  <div id="app-loading" ref="el" :class="{'transparent': semitransparent}">
    <div ref="eclipse" class="app-loading-white-eclipse"></div>
    <svg ref="loadingTextSvg" class="app-loading-text-svg">
      <defs>
        <linearGradient id="loader-gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1d6bf6" offset="40%" />
          <stop stop-color="#02acfe" offset="100%" />
        </linearGradient>
      </defs>
      <!-- desktop text -->
      <text class="hidden lg:block" x="50%" y="55%" text-anchor="middle" fill="#1b457e">
        Tortona
        <tspan fill="url(#loader-gradient)">Virtual Experience</tspan>
      </text>
      <!-- mobile text -->
      <text class="lg:hidden" x="50%" y="20%" text-anchor="middle">
        <tspan fill="#1b457e" x="50%" dy="1.2em">Tortona</tspan>
        <tspan fill="url(#loader-gradient)" x="50%" dy="1.2em">Virtual Experience</tspan>
      </text>
      <rect
        ref="mask"
        class="app-loading-text-svg-mask"
        width="15%"
        height="100%"
        fill="#fff"
        x="-15%"
        y="0"
        fill-opacity="1"
        mask="url(#loader-text-mask)"
      />
      <mask id="loader-text-mask" fill="#fff">
        <!-- desktop text mask -->
        <text class="hidden lg:block" x="50%" y="55%" text-anchor="middle">
          Tortona
          <tspan>Virtual Experience</tspan>
        </text>
        <!-- mobile text mask -->
        <text class="lg:hidden" x="50%" y="20%" text-anchor="middle">
          <tspan x="50%" dy="1.2em">Tortona</tspan>
          <tspan x="50%" dy="1.2em">Virtual Experience</tspan>
        </text>
      </mask>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
#app-loading {
  position: absolute;
  z-index: $z-preloader;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: $linear-gradient-background;

  &.transparent {
    background: $linear-gradient-background-transparent;
  }
}

.app-loading-white-eclipse {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 55%;
  background-color: rgba($c-white, 50%);
  border-radius: 50%;
  filter: blur(rem(100px));
  transform: translate(-50%, -50%);
}

.app-loading-text-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-family: "Tobias Regular", serif;
  font-size: rem(42px);
  letter-spacing: -0.05em;
  transform: translate(-50%, -50%);

  @screen beforeMd {
    font-size: 10vw;
  }

  tspan:last-child {
    font-style: italic;
  }
}

.app-loading-text-svg-mask {
  filter: blur(rem(4.2px));
}

</style>
